<template>
  <FormModal title="Type de toit" show-back-btn>
    <template #body>
      <RoofTypeElectricityEstimationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import RoofTypeElectricityEstimationFormStep from '~/components/form/steps/roofTypeElectricityEstimation/RoofTypeElectricityEstimationFormStep'

export default {
  components: {
    RoofTypeElectricityEstimationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle:
        'Type de toiture et estimation de la consommation électrique annuelle',
      headDescription:
        'Indiquez le type de toiture et votre estimation de la consommation électrique annuelle.',
      path: '/quotes-requests/type-toiture-et-consommation-de-la-consommation-electrique-annuelle',
      progressValue: 50,
      checkoutStep: 3,
    }
  },
}
</script>
